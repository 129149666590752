.blog-page {
  width: 100%;
  height: 100%;
}

.blog-page iframe {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  outline: none;
  border: none;
}

.blog-page a {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 50px;
  background-color: var(--second-color);
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: 900;
  font-size: 20px;
  text-decoration: none;
  transition: 0.3s;
}

.blog-page a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}