.projects-page {
  position: relative;
  align-self: flex-start;
}

.projects-page .filter-ul {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.projects-page .filter-ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100px;
  height: auto;
  border-radius: 10px;
  color: var(--text-color);
  border: 2px solid var(--second-color);
  box-shadow: 0 0 10px #00000033;
  cursor: pointer;
  transition: all 0.1s ease;
}

.projects-page .filter-ul li.active {
  background: var(--second-color);
}

.projects-page .filter-ul li:hover {
  transform: scale(1.1);
  background: var(--second-color);
}

.projects-page .filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-top: 150px;
  margin-bottom: 50px;
  overflow: hidden;
}

.projects-page .filter-container li {
  width: calc(100% / 3);
  height: 300px;
  background: transparent;
  overflow: hidden;
}

.projects-page .filter-container li .filter-item-content {
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.projects-page .filter-container li .filter-item-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media screen and (max-width: 992px) {
  .projects-page .filter-ul {
    gap: 35px;
  }

  .projects-page .filter-ul li {
    width: 75px;
    height: 75px;
    font-size: 14px;
  }

  .projects-page .filter-container li {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 768px) {
  .projects-page .filter-container li {
    width: 100%;
  }
}
