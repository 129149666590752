.fixed-whats {
  position: fixed;
  width: 110px;
  height: 55px;
  top: 35%;
  right: -55px;
}

.fixed-whats svg {
  width: 100%;
  height: 100%;
}

[dir="rtl"] .fixed-whats {
  right: unset;
  left: -55px;
}
