.icons .col-5 {
    align-items: center;
}

.icons .col-5 svg {
    cursor: pointer;
}

.icons .col-5 svg:hover * {
    fill: var(--second-color);
}

[data-theme="dark"] .icons .col-5 svg:hover * {
    fill: var(--main-color);
}

@media (max-width: 992px) {
    .icons {
        flex-direction: row;
        gap: 0;
    }

    .icons .col-5 {
        width: calc(100% / 5);
    }
}