.home-page .home-image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.home-page .home-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 335px;
  height: 335px;
  left: 50%;
  top: 1px;
  transform: translateX(-50%);
  border: dashed var(--main-color);
  border-width: 4px 0;
  border-radius: 50%;
  opacity: 0.5;
  z-index: -1;
  animation: animateCircle 1s linear infinite;
}

.home-page .home-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 335px;
  height: 335px;
  left: 50%;
  top: 1px;
  transform: translateX(-50%);
  border: dashed var(--text-color);
  border-width: 0 4px;
  border-radius: 50%;
  opacity: 0.5;
  z-index: -1;
  animation: animateCircle 1s linear infinite;
}

[data-theme="dark"] .home-page .home-image::before {
  border-color: #fff;
}

[data-theme="dark"] .home-page .home-image::after {
  border-color: var(--second-color);
}

@keyframes animateCircle {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }

  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

.home-page .home-image img {
  width: 100%;
  height: 350px;
  object-fit: contain;
}


.home-page .home-image h4 {
  font-size: 18px;
  text-align: center;
  font-family: var(--font-bold);
  color: var(--main-color);
}

[dir="rtl"] .home-page .home-image h4 {
  direction: ltr;
}

.home-page .home-image .icons .col-5 {
  align-items: center;
}

.home-page .home-image .icons .col-5 svg {
  cursor: pointer;
}

.home-page .home-info {
  gap: 50px;
}

.home-page .home-info h1 {
  -webkit-text-fill-color: transparent;
  -webkit-animation: animateText 3s linear infinite;
  animation: animateText 3s linear infinite;
  background: linear-gradient(118deg,
      var(--main-color) 50%,
      var(--second-color) 75%);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  color: #fff;
  font-size: 54px;
  text-align: left;
  text-transform: capitalize;
  line-height: 2;
}

[dir="rtl"] .home-page .home-info h1 {
  text-align: right;
}

.home-page .home-info h1 span {
  font-family: var(--font-bold);
}

.home-page .home-info p {
  font-size: 18px;
  text-align: left;
  color: var(--text-color);
  font-family: var(--font-bold);
  line-height: 2.5;
}

[dir="rtl"] .home-page .home-info p {
  text-align: right;
}

.home-page .home-info .btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 992px) {
  .home-page .row {
    flex-direction: column-reverse;
  }

  .home-page .icons {
    flex-direction: row;
    gap: 0;
  }

  .home-page .icons .col-5 {
    width: calc(100% / 5);
  }

  .home-page .home-info h1 {
    font-size: 40px;
  }

  .home-page .home-info p {
    font-size: 16px;
  }

  .home-page .home-info .btns {
    margin-bottom: 20px;
  }

  .home-page .home-info .btns a {
    width: 100%;
  }
}

@media (max-width: 400px) {

  .home-page .home-image::before,
  .home-page .home-image::after {
    top: 30px;
    width: 275px;
    height: 275px;
  }
}