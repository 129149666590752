.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #00000099; */
    z-index: -1;
}

.loading .spinner {
    position: absolute;
    width: 75px;
    height: 75px;
    border: 2.5px solid #f3f3f3;
    border-top: 2.5px solid #3498db;
    border-radius: 50%;
    animation: spinner 0.5s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}