.header {
  position: relative;
  height: 70px;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 2px 4px #0000001a;
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-theme="dark"] .header {
  background-color: var(--bg-color);
}

.header .header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .header-content .header-logo {
  height: 60px;
}

.header .header-content .header-logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.header .header-content .header-logo a img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.header .header-content .header-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  list-style: none;
}

.header .header-content .header-links li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .header-content .header-links li a {
  color: var(--text-color);
  font-family: var(--font-bold);
  font-size: 16px;
  text-decoration: none;
}

.header .header-content .header-links li a:hover {
  color: var(--main-color);
}

.header .header-content .header-links li a.active {
  color: var(--main-color);
}

.header .header-content .header-links li a.contact {
  color: var(--main-color);
}

.header .header-content .header-links li a.contact:hover {
  color: #fff;
}

.header .header-content .header-links li a.contact.active {
  background-color: var(--main-color);
  color: #fff;
}

.header .header-content .header-links li.dark-mode svg {
  cursor: pointer;
  height: 40px;
}

[data-theme="light"] .header .header-content .header-links li.dark-mode svg * {
  fill: var(--main-color);
}

@media (max-width: 992px) {
  .header {
    height: 60px;
  }

  .header .header-content .header-logo {
    height: 50px;
  }

  .header .header-content .header-links {
    gap: 5px;
  }

  .header .header-content .header-links li {
    display: none;
  }

  .header .header-content .header-links li.cv-btn {
    display: flex;
  }

  .header .header-content .header-links li.cv-btn a {
    padding: 10px 20px;
    font-size: 12px;
  }

  .header .header-content .header-links li.dark-mode {
    display: flex;
  }
}

@media (max-width: 370px) {
  .header .header-content .header-logo {
    height: 35px;
  }

  .header .header-content .header-links li.dark-mode svg {
    width: 30px;
    height: 30px;
  }

  .header .header-content .header-links li.cv-btn a {
    padding: 10px 20px;
    font-size: 10px;
  }
}