.cv-page {
  width: 55%;
  height: 100%;
  border: unset;
  padding: 0;
  margin: 0;
}

@media (max-width: 1200px) {
  .cv-page {
    width: 100%;
  }
}