.about-page .row {
  margin: 100px auto;
}

.about-page .about-image svg {
  width: 100%;
  height: 350px;
  object-fit: contain;
}

.about-page .about-info {
  gap: 30px;
}

.about-page .about-info h1 {
  font-family: var(--font-bold);
  -webkit-text-fill-color: transparent;
  -webkit-animation: animateText 3s linear infinite;
  animation: animateText 3s linear infinite;
  background: linear-gradient(
    118deg,
    var(--main-color) 50%,
    var(--second-color) 75%
  );
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  color: #fff;
  font-size: 54px;
  text-align: left;
  text-transform: capitalize;
  line-height: 2;
}

[dir="rtl"] .about-page .about-info h1 {
  text-align: right;
}

.about-page .about-info p {
  font-size: 18px;
  text-align: left;
  font-family: var(--font-bold);
  color: var(--text-color);
  line-height: 2.5;
}

[dir="rtl"] .about-page .about-info p {
  text-align: right;
}

.about-page .about-info .tools {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.about-page .about-info .tools span {
  font-size: 14px;
  color: var(--main-color);
  background-color: #e5eeff;
  padding: 5px 10px;
  border-radius: 100px;
}

[data-theme="dark"] .about-page .about-info .tools span {
  background-color: var(--bg-color);
}

@media (max-width: 992px) {
  .about-page .row:not(:nth-child(2)) {
    flex-direction: column-reverse;
  }

  .about-page .about-info h1 {
    font-size: 40px;
  }

  .about-page .about-info p {
    font-size: 16px;
  }
}

/* Animate SVGS */

#Rectangle_95 {
  animation: rectangle1 0.3s alternate infinite;
}

#Rectangle_96 {
  animation: rectangle1 0.5s alternate infinite;
}

#Rectangle_97 {
  animation: rectangle1 0.7s alternate infinite;
}

@keyframes rectangle1 {
  0% {
    fill: var(--second-color);
  }
  50% {
    fill: #e6e6e6;
  }
  100% {
    fill: var(--second-color);
  }
}

#Ellipse_7 {
  animation: ellipse 2s linear infinite;
}

@keyframes ellipse {
  0% {
    transform: translate(220px, 0);
  }
  50% {
    transform: translate(220px, 10px);
  }
  100% {
    transform: translate(220px, 0);
  }
}

#Path_77 {
  animation: path1 2s linear infinite;
}

@keyframes path1 {
  0% {
    transform: translate(-152px, -60px);
    fill: var(--main-color);
  }
  50% {
    transform: translate(-152px, -62px);
    fill: #e5eeff;
  }
  100% {
    transform: translate(-152px, -60px);
    fill: var(--main-color);
  }
}

#Path_78 {
  animation: path2 2s alternate infinite;
}

@keyframes path2 {
  0% {
    transform: translate(-175px, -60px);
    fill: var(--second-color);
  }
  50% {
    transform: translate(-175px, -62px);
    fill: #fff;
  }
  100% {
    transform: translate(-175px, -60px);
    fill: var(--second-color);
  }
}

#Path_159 {
  animation: path3 5s alternate infinite;
}

@keyframes path3 {
  0% {
    transform: translate(-275px, -300px);
  }
  50% {
    transform: translate(-280px, -298px);
  }
  100% {
    transform: translate(-275px, -300px);
  }
}

#Path_162 {
  animation: path4 5s alternate infinite;
}

@keyframes path4 {
  0% {
    transform: translate(-288px, -294px);
  }
  50% {
    transform: translate(-287px, -296px);
  }
  100% {
    transform: translate(-288px, -294px);
  }
}

#Path_199 {
  animation: path5 3s alternate infinite;
}
#Path_195 {
  animation: path5 3s alternate-reverse infinite;
}

#Path_191 {
  animation: path5 3s alternate infinite;
}

@keyframes path5 {
  0% {
    fill: var(--main-color);
  }
  50% {
    fill: #aac8ff;
  }
  100% {
    fill: var(--main-color);
  }
}

#Path_128 {
  animation: path6 2s alternate-reverse infinite;
}

@keyframes path6 {
  0% {
    fill: var(--second-color);
  }
  50% {
    fill: #e6e6e6;
  }
  100% {
    fill: var(--second-color);
  }
}

#Path_124,
#Path_125,
#Path_126,
#Path_127,
#Path_136,
#Path_142,
#Path_147,
#Path_154 {
  animation: path7 2s alternate infinite;
}

@keyframes path7 {
  0% {
    fill: var(--main-color);
  }
  50% {
    fill: #e6e6e6;
  }
  100% {
    fill: var(--main-color);
  }
}

#Rectangle_131 {
  animation: rectangle2 0.3s alternate infinite;
}

#Rectangle_132 {
  animation: rectangle2 0.5s alternate infinite;
}

#Rectangle_133 {
  animation: rectangle2 0.7s alternate infinite;
}

@keyframes rectangle2 {
  0% {
    fill: var(--main-color);
  }
  50% {
    fill: #e6e6e6;
  }
  100% {
    fill: var(--main-color);
  }
}
