.language {
  position: fixed;
  top: 25%;
  right: 0;
  width: 35px;
  height: 40px;
  background: #e5eeff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50% 0px 0px 50%;
  color: var(--main-color);
  border: 1px solid #b3cdfd;
  font-family: var(--font-bold);
  line-height: 0;
  z-index: 99999;
}

[data-theme="dark"] .language {
  color: var(--second-color);
}

.language:hover {
  background: #b3cdfd;
}

[dir="rtl"] .language {
  right: unset;
  left: 0;
  border-radius: 0px 50% 50% 0px;
}
