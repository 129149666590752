.contact-page h1 {
  font-family: var(--font-bold);
  -webkit-text-fill-color: transparent;
  -webkit-animation: animateText 3s linear infinite;
  animation: animateText 3s linear infinite;
  background: linear-gradient(118deg,
      var(--main-color) 50%,
      var(--second-color) 75%);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  color: #fff;
  font-size: 54px;
  text-align: center;
  text-transform: capitalize;
  line-height: 2;
}

.contact-page p {
  font-size: 18px;
  text-align: center;
  font-family: var(--font-bold);
  color: var(--text-color);
  line-height: 2.5;
}

.contact-page .contact-form {
  margin-top: 50px;
}

.contact-page .contact-form {
  gap: 30px;
}

.contact-page .contact-form .col-3 {
  width: calc(100% / 3 - 30px);
}

.contact-page .contact-form .input-group {
  position: relative;
}

.contact-page .contact-form input {
  height: 55px;
  width: 100%;
  border: none;
  border: 1px solid #eee;
  color: var(--text-color);
  border-radius: 100px;
  padding: 10px 15px;
  font-size: 16px;
  outline: none;
}

[data-theme="dark"] .contact-page .contact-form input {
  background-color: var(--bg-color);
  border: 1px solid #292f3a;
}

.contact-page .contact-form input:focus {
  border: 1px solid var(--main-color);
}

.contact-page .contact-form textarea {
  width: 100%;
  border: none;
  border: 1px solid #eee;
  color: #292f3a;
  border-radius: 30px;
  padding: 20px;
  font-size: 16px;
  outline: none;
  resize: none;
  height: 150px;
}

[data-theme="dark"] .contact-page .contact-form textarea {
  background-color: var(--bg-color);
  border: 1px solid #292f3a;
  color: #fff;
}

.contact-page .contact-form textarea:focus {
  border: 1px solid var(--main-color);
}

.contact-page .contact-form label {
  position: absolute;
  top: -10px;
  left: 20px;
  font-size: 12px;
  color: var(--text-color);
  font-family: var(--font-bold);
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
}

[data-theme="dark"] .contact-page .contact-form label {
  color: var(--text-color);
  background-color: var(--bg-color);
}

[dir="rtl"] .contact-page .contact-form label {
  right: 20px;
  left: unset;
}

.contact-page .contact-form input[type="submit"] {
  border: none !important;
  background-color: var(--main-color);
  color: #fff;
}

.contact-page .contact-form input[type="submit"]:hover {
  background-color: var(--second-color);
}

@media (max-width: 992px) {
  .contact-page .contact-form .col-3 {
    width: 100%;
  }

  .contact-page h1 {
    font-size: 40px;
  }

  .contact-page p {
    font-size: 16px;
  }
}