.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  box-shadow: 0px -1px 5px #0000001a;
  background-color: #fff;
}

[data-theme="dark"] .footer {
  background-color: var(--bg-color);
}

.footer .footer-links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .footer-links li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
}

.footer .footer-links li p {
  font-size: 16px;
  color: #fff;
  font-family: var(--font-bold);
}

.footer .footer-links li a {
  color: var(--text-color);
  font-family: var(--font-bold);
  font-size: 18px;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #0000001a;
  border-left: 1px solid #0000001a;
}

.footer .footer-links li a.active {
  color: #fff;
  background-color: var(--second-color);
}

.footer .footer-links li a svg {
  height: 25px;
}

.footer .footer-links li a.active svg * {
  fill: #fff;
}

@media (max-width: 768px) {
  .footer .footer-links li p {
    font-size: 10px;
  }
}