@font-face {
  font-family: "bukra-bold";
  src: url(./assets/fonts/Bukra-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "bukra-regular";
  src: url(./assets/fonts/Bukra-Regular.ttf) format("truetype");
}

:root {
  --main-color: #3766c3;
  --second-color: #ffc400;
  --text-color: #333333;
  --bg-color: #fff;
  --font-bold: "bukra-bold";
  --font-regular: "bukra-regular";
}

[data-theme="dark"] {
  --main-color: #ffc400;
  --second-color: #3766c3;
  --bg-color: #313a49;
  --text-color: #fff;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #a7a7a7;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--main-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-regular), sans-serif;
  transition: all 0.1s ease;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  background-color: #fff;
}

[data-theme="dark"] body {
  background-color: var(--bg-color);
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.075;
  z-index: -1;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.page {
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.col-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.col-2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.col-3 {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.col-4 {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.col-5 {
  width: calc(100% / 5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 992px) {
  .row {
    flex-direction: column;
    gap: 50px;
  }

  .col-2 {
    width: 100%;
  }

  .col-4 {
    width: 100%;
  }

  .col-5 {
    width: 100%;
  }

  .page {
    padding-bottom: 60px;
  }
}

.main-btn {
  display: inline-block;
  padding: 10px 40px;
  background-color: var(--main-color);
  color: #fff;
  border: 1px solid var(--main-color);
  border-radius: 50px;
  font-size: 16px;
  font-family: var(--font-bold);
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.outline-btn {
  display: inline-block;
  padding: 10px 40px;
  background-color: transparent;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 50px;
  font-size: 16px;
  font-family: var(--font-bold);
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.main-btn:hover {
  background-color: var(--second-color);
  border: 1px solid var(--second-color);
  color: #fff;
}

.outline-btn:hover {
  background-color: var(--main-color);
  color: #fff;
}

@keyframes animateText {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}